<template>
  <!-- 任务管理列表 -->
  <div class="task-management"  v-if="isshow">
    <!--  任务管理开始-->
    <div class="task-management-all">
      <van-dropdown-menu>
        <van-dropdown-item :title="task_value" ref="tasking">
          <div class="screening-con">
            <van-cell center :title-class="['screening-title']" :class="{'screening-title-act': screen_index_task == index}"
                      :title="item.name" v-for="(item, index) in smallTask" @click="screenFuntask(item, index)"
                      :key="index"></van-cell>
          </div>

          <div class="btn-con">
            <div class="cancel align-center" @click="screeningCanchetask">取消</div>
            <div class="confirm align-center" @click="screenSubmittask">确认</div>
          </div>
        </van-dropdown-item>
        <van-dropdown-item :title="team_value +'(' + teamTotal +')'" ref="screening"  v-if="userInfo.is_team_master">
          <div class="screening-con">
            <van-cell center :title-class="['screening-title']" :class="{'screening-title-act': screen_index == index}"
                      :title="item.name" v-for="(item, index) in smallTeam" @click="screenFun(item, index)"
                      :key="index"></van-cell>
          </div>

          <div class="btn-con">
            <div class="cancel align-center" @click="screeningCanche">取消</div>
            <div class="confirm align-center" @click="screenSubmit">确认</div>
          </div>
        </van-dropdown-item>
      </van-dropdown-menu>
    </div>
    <div class="notification notification-padding" >
      <!--      <van-list
        v-model:loading="loading"
        :finished="finished"
        :finished-text="taskInfo.length > 7 ? '没有更多了' : finishedText "
        @load="onLoad"
      >

      </van-list>-->

      <div class="">
        <van-tabs v-model:active="activeName" bind:change="onChange" title-active-color="#FF6B29"
                  title-inactive-color="#4D4D4D"
                  line-width="70" line-height="3" @click="onClick"
                  class="custom-class">
          <!--      待处理开始-->
          <van-tab title="待处理" class="pending" :name="'false'" title-class="title-class">
            <van-checkbox-group v-model="checked" ref="checkboxGroup">
              <van-swipe-cell class="swipe-cell" :class="checked.indexOf(item.id)> -1 ? 'swipe-cell-bg' : ''"
                              v-for="item in taskInfo" :key="item.id" @open="openSwipe(item.id)" @close="closeSwipe">
                <van-cell-group>
                  <van-checkbox :name="item.id" checked-color="#FF6B29" class="checkbox" label-disabled
                                :class="isCheck === item.id ? 'check-acitve ' : ''" @click.stop="onCheckbox(item.id)">
                    <div class="team-con" @click.stop="goShow(item.id)">
                      <div class="team-item">
                        <div class="team-user-info">
                          <!--                                                    <img class="apply-portrait" :src="item.task.cover_picture ? item.task.cover_picture : $confStatic.taskImg" />-->
                          <!--<div class="apply-title white-space">{{ item.task && item.task.name }}</div>
                                                    <div class="apply-time">{{ item.task && (item.task.rule.detail.mode === 'increase' ? '+' : '-')
                          }}{{ item.task && item.task.rule.detail.amount
                          }}{{ item.task.rule.type === 'credit' ? '积分' : "经验值" }}</div>-->
                          <div class="team-info-r">
                            <div class="space-between flex apply-title-con">
                              <div class="apply-title white-space">{{ item.task && item.task.name }}</div>
                              <div class="apply-time">{{
                                  item.task && (item.task.rule.detail.mode === 'increase' ? '+' : '-')
                                }}{{
                                  item.task && item.task.rule.detail.amount
                                }}{{ item.task.rule.type === 'credit' ? '积分' : "经验值" }}
                              </div>
                            </div>
                            <div class="team-info-bottom space-between">
                              <div class="user-info vertical-center">
                                <img class="user-info-avant"
                                     :src="item.user_wechat && item.user_wechat.avatar ? item.user_wechat.avatar : 'https://static.helixlife.cn/edu/home/images/avatar.png'"
                                     alt="">
                                <div class="user-info-nickName white-space">
                                  {{ item.user_wechat ? item.user_wechat.nickname : '酸谈社群' }}
                                </div>
                                <div class="serial-number" v-if="item.user_profile && item.user_profile.number">编号：{{item.user_profile.number}}</div>
                              </div>
                              <div class="apply-state">{{ item.created_at }}</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </van-checkbox>
                </van-cell-group>
                <template #right>
                  <van-button square class="adopt" type="danger" text="通过" @click="adopt(item.id)"/>
                  <van-button square class="refuse" type="primary" text="驳回" @click="reject(item.id)"/>
                </template>
              </van-swipe-cell>
            </van-checkbox-group>

          </van-tab>
          <!--      待处理结束-->

          <!--      已处理开始-->
          <van-tab title="已处理" :name="'true'" :class="taskInfo.length == 0 ? 'pending' : ''">
            <div class="processed">
              <div :class="taskInfo.length == 0 ? '' : 'team-con'">
                <div class="team-item" v-for="item in taskInfo" :key="item.id">
                  <div class="team-user-info" @click="goShow(item.id)">
                    <div class="team-info-r">
                      <div class="space-between flex apply-title-con">
                        <div class="apply-title white-space">{{ item.task && item.task.name }}</div>
                        <div class="apply-time">{{
                            item.task && (item.task.rule.detail.mode === 'increase' ? '+' : '-')
                          }}{{
                            item.task && item.task.rule.detail.amount
                          }}{{ item.task.rule.type === 'credit' ? '积分' : "经验值" }}
                        </div>
                      </div>
                      <div class="team-info-bottom space-between">
                        <div class="user-info vertical-center">
                          <img class="user-info-avant"
                               :src="item.user_wechat && item.user_wechat.avatar ? item.user_wechat.avatar : 'https://static.helixlife.cn/edu/home/images/avatar.png'"
                               alt="">
                          <div class="user-info-nickName white-space">
                            {{ item.user_wechat ? item.user_wechat.nickname : '酸谈社群' }}
                          </div>

                          <div class="serial-number" v-if="item.user_profile && item.user_profile.number">编号：{{item.user_profile.number}}</div>
                        </div>
                        <div class="apply-state">{{ item.updated_at }}</div>
                      </div>
                      <div class="reject-tip" v-if="item.status === -1">
                        <div class="examine">
                          <span class="reason"
                                v-if="item.status === -1 ? item.progress_log.remark : ''"
                                @click.stop="clickMroe(item.id)">失败原因
                          <img class="reason-icon"
                               :src="require(isShowMore === item.id ? '../../../../assets/images/user/fold-up.png' : '../../../../assets/images/user/fold-down.png')"
                               alt="">
                          </span>
                        </div>
                        <div class="reason-text" v-if="isShowMore === item.id">原因：{{ item.progress_log.remark }}</div>

                      </div>
                    </div>
                    <div class="examine-tip" :class="[item.status === -1 ? '' : 'success']">
                      {{ item.status === -1 ? '审核驳回' : '审核通过' }}
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </van-tab>
          <!--      已处理结束-->
        </van-tabs>
        <no-data v-if="taskInfo.length == 0 && request_status" ></no-data>
        <!--  分页  -->
        <div class="align-center" v-if="taskInfo.length > 0 && pageInfo.last_page > 1">
          <div class="set-page-box vertical-center">
            <div class="statistics-box">共<span
                class="current-page-num">{{ pageInfo.current_page }}</span>/{{ pageInfo.last_page }}页
            </div>
            <div class="page-btn-box vertical-center">
              <p class="prev-btn" :class="{'page-btn-set-act': (pageInfo.current_page == pageInfo.last_page) && pageInfo.last_page > 1}" @click="prevFun">上一页</p>
              <p class="next-btn" :class="{'page-btn-set-act': (pageInfo.current_page != pageInfo.last_page) && pageInfo.last_page > 1}" @click="nextFun">下一页</p>
            </div>
            <div class="go-page-box vertical-center">
              <span>前往</span>
              <input type="tel" v-model="current_page_value" @keyup.enter="handleSearch" maxlength="3" class="go-page-inp">
              <span>页</span>
            </div>
            <div class="page-submit" @click="handleSearch">确定</div>
          </div>
        </div>
      </div>
    </div>
    <!--  任务管理结束-->

    <!--  批量按钮开始-->
    <div class="batch-operation-height safety-height"></div>
    <div class="batch-operation-box safety-height" v-show="activeName== 'false' && taskInfo && taskInfo.length !== 0">
      <div class="batch-operation" v-if="isShowBatch || taskInfo.length == 0">
        <div class="batch-con">
          <div class="batch-select-all" @click="checkAll">
            <van-checkbox v-model="checkboxAll" checked-color="#FF6B29" class="batch-select-icon"
                          :disabled="isDisabled">
              <div class="batch-select-title">全选</div>
            </van-checkbox>
          </div>

          <div class="batch-rejection" @click="clickBtn('rejection')">批量驳回</div>
          <div class="batch-pass" @click="clickBtn('pass')">批量通过</div>
        </div>
      </div>
    </div>
    <!--    批量按钮结束-->

    <!--审核驳回提示开始-->
    <van-overlay :show="isRejectShow" z-index="9999">
      <div class="overlay-reject-con align-center">
        <div class="overlay-reject">
          <div class="overlay-reject-head">
            <div></div>
            <div class="overlay-reject-text">确认审核</div>
            <img class="overlay-reject-head-img" src="~assets/images/user/overlay-cancel.png"
                 @click="closeOverla('rejection')"/>
          </div>
          <div class="overlay-reject-weiper">
            <div class="overlay-reject-text">选择以下驳回原因驳回后将无法恢复，确定继续吗？</div>
            <div class="overlay-reject-info">
              <van-radio-group v-model="checkedRadio" class="overlay-reject-radio-group">
                <!--驳回提示选项-->
                <van-radio use-icon-slot value="{{ radio }}" :name="item.id" label-class="label-class"
                           class="overlay-reject-radio-group-item" v-for="item in rejectTip" :key="item.id">
                  <template #icon="props">
                    <img class="overlay-reject-icon"
                         :src="checkedRadio === item.id ? require('assets/images/user/overlay-redio2.png') : require('assets/images/user/overlay-redio1.png')"/>
                  </template>
                  <template #default="defaul">
                    <div class="label-class">{{ item.text }}</div>
                  </template>
                </van-radio>
                <van-field
                    v-model="otherMessage"
                    type="textarea"
                    placeholder="请输入其他原因"
                    class="other"
                    v-show="checkedRadio === 3"
                />
                <!--驳回提示选项-->
              </van-radio-group>
            </div>
          </div>
          <div class="overlay-btn">
            <div class="confirm" @click="closeOverla('rejection')">再想想</div>
            <div class="cancel" @click="operation('rejection', ids)">确定</div>
          </div>
        </div>
      </div>
    </van-overlay>
    <!--审核驳回提示结束-->

    <!--确认审核提示开始-->
    <van-overlay :show="isConfirmShow" z-index="9999">
      <div class="overlay-confirmation-audit-con align-center">
        <div class="overlay-confirmation-audit">
          <div class="overlay-confirmation-head">
            <div class="overlay-reject-text">确认审核</div>
            <img class="overlay-confirmation-head-img" src="~assets/images/user/overlay-cancel.png"
                 @click="closeOverla('pass')"/>
          </div>
          <div class="overlay-confirmation-body vertical-center">
            <img class="overlay-confirmation-tip-img" src="~assets/images/user/overlay-tip.png"/>
            <div class="overlay-confirmation-tip-text">一旦通过，无法撤回。是否确认审核通过？</div>
            <div class="overlay-btn">
              <div class="cancel" @click="closeOverla('pass')">取消</div>
              <div class="confirm" @click="operation('pass', ids)">确定</div>
            </div>
          </div>
        </div>
      </div>
    </van-overlay>
    <!--确认审核提示结束-->
    <van-toast id="van-toast"/>
  </div>
</template>

<script>
import BottomTip from "components/BottomTip";
import NoData from "@/components/NoData";
export default {
  name: "sign",
  data() {
    return {
      isshow:false,
      activeName: 'false',    //选择的tab
      checked: [],            //点击的checkBok数组
      page: 1,                //页码
      is_deal: false,         //
      taskInfo: [],            //通知列表
      checkboxAll: false,     //全选按钮
      isShowBatch: true,       //是否显示底部按钮
      isCheck: false,
      isConfirmShow: false, //是否显示确认审核提示
      isRejectShow: false, //审核驳回提示
      checkedRadio: 0,
      rejectTip: [
        {id: 0, text: '截图不清晰，无法辨别，请重新上传'},
        {id: 1, text: '涉嫌违反法律法规、社会公德良知，涉及反动、泄密、色情赌博、诈骗、售假、暴力等内容。'},
        {id: 2, text: '未按正确流程操作'},
        {id: 3, text: '其他原因'},
      ],
      loading: false,
      finished: false,
      isDisabled: false,   //是否禁用
      ids: '',
      otherMessage: '',
      isShowMore: true,
      finishedText: '',
      swipe_cell_arr: [],
      value: 0,
      smallTeam: [],            //小队列表
      smallTask: [
        {
          id:0,
          name:'任务包'
        },
        {
          id:1,
          name:'限时任务'
        }
      ],     //任务列表
      team_value: '全部小队', // 选择值
      task_value: '任务包', // 选择值
      teamTotal: 0,  //数量
      screen_index: 0,  // 筛选的下标
      screen_index_task:0,
      current_value: {
        id:-1,
        name:'全部小队'
      },  // 当前选择的值
      current_value_task: {
        id:0,
        name:'任务包'
      },  // 当前选择的值
      team_group_id: -1,
      task_group_id:0,
      pageInfo: {}, // 分页信息
      current_page_value: 1,
      request_status: false
    }
  },
  created() {
    setTimeout(()=>{
      this.isshow = true
    },200)
    if (this.userInfo && this.userInfo.is_team_master) {
      this.getTeamTags()
    }
    if(this.$route.query.page) {
      this.page= this.$route.query.page
      this.current_page_value = this.$route.query.page
    }
    if(this.$route.query.activeName) {
      this.activeName= this.$route.query.activeName
    }
    if(this.$route.query.task_name){
      this.team_group_id = this.$route.query.team_id
      this.team_value = this.$route.query.team_name
      this.task_group_id = this.$route.query.task_id
      this.task_value = this.$route.query.task_name
      this.current_value.id = this.$route.query.team_id
      this.current_value.name = this.$route.query.team_name
      this.current_value_task.id = this.$route.query.task_id
      this.current_value_task.name = this.$route.query.task_name
      this.screen_index = this.$route.query.team_index
      this.screen_index_task = this.$route.query.task_index
    }

    this.getTaskInfo()
  },
  methods: {
    // 关闭滑块
    closeSwipe() {
      this.isCheck = false
    },
    // 打开滑块
    openSwipe(id) {
      this.isCheck = id
    },
    // 获取小队列表
    getTeamTags() {
      let _this = this
      let url = _this.$api.UserTeamTags
      _this.$http.get(url, true).then(res => {
        if (res.data.success) {
          this.smallTeam = res.data.data
          this.smallTeam.unshift({id: -1, name: '全部小队'})
          // this.team_value = '全部小队'
          // this.team_group_id = -1

        }
      })
    },
    //获取通知
    getTaskInfo() {
      let _this = this
      let url = _this.$api.Tasknot + `?page=${_this.page}&is_deal=${_this.activeName}&pageSize=50&op_type=${_this.task_group_id}`
      if (this.userInfo.is_team_master) {
        url = _this.$api.Tasknot + `?page=${_this.page}&is_deal=${_this.activeName}&group_id=${_this.team_group_id}&pageSize=50&op_type=${_this.task_group_id}`
      }
      this.request_status= false
      _this.finished = true;
      _this.$http.get(url, true).then(res => {
        if (res.data.success) {
          this.request_status= true
          // _this.taskInfo = res.data.data.data
          this.loading = false;
          _this.teamTotal = res.data.data.total
          _this.pageInfo = res.data.data;
          if (res.data.data.data.length) {
            this.finished = false;
            //_this.taskInfo = _this.taskInfo.concat(res.data.data.data)
            _this.taskInfo = res.data.data.data
            _this.finishedText = ''
            if (res.data.data.data) {
              _this.isDisabled = false
              _this.checkboxAll = false
            }
          } else {
            this.finished = true;
            if (_this.taskInfo.length === 0) {
              _this.isDisabled = true
              _this.checkboxAll = false
              _this.finishedText = '暂无数据'
            }
          }
        }else {
          this.$router.replace('/user')
        }
      })
    },
    // 点击复选框
    onCheckbox(id) {
      this.checkboxAll = (this.checked.length === this.taskInfo.length)

    },
    //通过操作
    adopt(id) {
      this.isConfirmShow = true
      this.ids = id
    },
    //驳回操作
    reject(id) {
      this.isRejectShow = true
      this.ids = id
      this.checkedRadio = 0

    },
    //点击操作
    operation(type, ids) {
      let _this = this
      _this.page = 1
      let url = _this.$api.Notifications
      if (type === 'pass') {
        let data = {
          action: 'access',
          source: 'task',
          ids
        }
        _this.taskInfo = []

        _this.$http.post(url, data, true).then(res => {
          if (res.data.success) {
            _this.isCheck = false
            _this.checked = []
            _this.isConfirmShow = false
            _this.ids = ''
            _this.teamTotal = 0
            _this.getTaskInfo()
          } else {
            _this.isCheck = false
            _this.checked = []
            _this.isConfirmShow = false
            _this.ids = ''
            _this.teamTotal = 0
            _this.$toast(res.data.message)
            _this.getTaskInfo()
          }
        })

      } else if (type === 'rejection') {
        let data = {
          action: 'reject',
          source: 'task',
          // remark: '',
          remark: _this.rejectTip[this.checkedRadio].text,
          ids
        }

        if (_this.checkedRadio === 3) {
          if (_this.otherMessage.trim() === '') {
            _this.$toast("请输入驳回原因")
            return
          }
          data.remark = _this.otherMessage
        }
        _this.taskInfo = []

        _this.$http.post(url, data, true).then(res => {
          if (res.data.success) {
            _this.isCheck = false
            _this.otherMessage = ''
            _this.ids = ''
            _this.checked = []
            _this.isRejectShow = false
            _this.teamTotal = 0
            _this.getTaskInfo()
          } else {
            _this.isCheck = false
            _this.otherMessage = ''
            _this.ids = ''
            _this.checked = []
            _this.isRejectShow = false
            _this.teamTotal = 0
            _this.$toast(res.data.message)
            _this.getTaskInfo()
          }
        })
      }
    },
    // 点击全选
    checkAll() {
      const {checkboxGroup} = this.$refs
      if (this.checkboxAll) {
        checkboxGroup.toggleAll(true)
      } else {
        checkboxGroup.toggleAll(false)
      }

    },
    // 点击切换分类
    onClick(value) {
      this.$toast.loading({
        message: '加载中...',
        forbidClick: true,
        duration: 1000
      });
      this.taskInfo = []
      this.checked = []
      this.page = 1
      this.current_page_value= 1
      this.teamTotal = 0
      if (value == 'true') {
        this.isShowBatch = false
      } else {
        this.isShowBatch = true
      }
      this.$router.push({
        query: {
          page: 1,
          activeName: this.activeName
        },
      });
      this.getTaskInfo()
    },
    //点击批量操作
    clickBtn(type) {
      this.checkedRadio = 0

      if (this.checked.length === 0) {
        this.$toast('请勾选');
        return
      }
      if (type === 'pass') {
        this.isConfirmShow = true

      } else if (type === 'rejection') {
        this.isRejectShow = true
      }
      this.ids = this.checked.toString()
    },
    onLoad() {
      this.page++;
      this.getTaskInfo()
    },
    // 取消操作
    closeOverla(type) {
      if (type === 'pass') {
        this.isConfirmShow = false

      } else if (type === 'rejection') {
        this.isRejectShow = false
        this.otherMessage = ''
        // this.checkedRadio = 0
      }
    },
    // 跳转详情
    goShow(id) {
      this.$router.push({
        path: "/user/task/show/" + id,
        query: {
          task_id: this.current_value_task.id,
          task_name: this.current_value_task.name,
          task_index:this.screen_index_task,
          team_id: this.current_value.id,
          team_name: this.current_value.name,
          team_index:this.screen_index
        }
      })
    },
    //点击失败原因
    clickMroe(id) {
      if (this.isShowMore == id) {
        this.isShowMore = -1
      } else {
        this.isShowMore = id
      }
    },
    // 点击筛选
    screenFun(item, index) {
      this.screen_index = index
      this.current_value = item
    },
    // 点击筛选
    screenFuntask(item, index) {
      this.screen_index_task = index
      this.current_value_task = item
    },
    // 确认筛选
    screenSubmit() {
      this.$refs.screening.toggle(false)
      // 回复默认值
      // this.defaultFun()
      this.taskInfo = []
      this.checked = []
      this.page = 1
      this.current_page_value= 1
      this.$router.push({
        query: {
          page: 1,
          activeName: this.activeName
        },
      });
      if (this.current_value != null) {
        this.team_group_id = this.current_value.id
        this.team_value = this.current_value.name
      }
      this.getTaskInfo()
    },
    // 任务确认筛选
    screenSubmittask() {
      this.$refs.tasking.toggle(false)
      // 回复默认值
      // this.defaultFun()
      this.taskInfo = []
      this.checked = []
      this.page = 1
      this.current_page_value= 1
      this.$router.push({
        query: {
          page: 1,
          activeName: this.activeName
        },
      });
      if (this.current_value_task != null) {
        this.task_group_id = this.current_value_task.id
        this.task_value = this.current_value_task.name
      }
      this.getTaskInfo()
    },
    // 关闭弹框
    screeningCanche() {
      this.$refs.screening.toggle(false)
    },
    screeningCanchetask() {
      this.$refs.tasking.toggle(false)
    },

    // 点击上一页
    prevFun() {
      if((this.pageInfo.current_page -1) > 0) {
        this.page = this.pageInfo.current_page - 1
        this.current_page_value= this.pageInfo.current_page - 1
        this.$router.push({path: '/user/task', query: {
          page: this.pageInfo.current_page - 1,
          activeName: this.activeName
        }})
        this.defaultFun()
      }
    },
    // 点击下一页
    nextFun() {
      if((this.pageInfo.current_page + 1) < this.pageInfo.last_page || (this.pageInfo.current_page + 1) == this.pageInfo.last_page) {
        this.page = this.pageInfo.current_page + 1
        this.current_page_value= this.pageInfo.current_page + 1
        this.$router.push({path: '/user/task', query: {
            page: this.pageInfo.current_page + 1,
            activeName: this.activeName
        }})
        this.defaultFun()
      }
    },
    // 点击搜索跳转
    handleSearch() {
      if(!parseInt(this.current_page_value)) {
        return this.$toast('请输入正确的页码')
      }
      if(parseInt(this.current_page_value) > this.pageInfo.last_page) {
        return this.$toast('请输入正确的页码')
      }
      if(parseInt(this.current_page_value) < 1) {
        return this.$toast('请输入正确的页码')
      }
      this.page= parseInt(this.current_page_value)
      this.$router.push({path: '/user/task', query: {
          page: parseInt(this.current_page_value),
          activeName: this.activeName
      }})
      this.defaultFun()
    },
    // 默认执行方法
    defaultFun() {
      this.$toast.loading({
        message: '加载中...',
        forbidClick: true,
        duration: 1000
      });
      this.taskInfo = []
      this.checked = []
      this.getTaskInfo()
    }
  },
  components: {
    BottomTip,
    NoData
  },
  watch: {
    userInfo(val, oldVal) {
      return val;
    }
  },
  computed: {
    userInfo() {
      return this.$store.getters.userInfo;
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~assets/scss/user/task-management/index.scss";

$r: 750/16/1rem;
.other {
  width: 521/$r;
  height: 130/$r;
  margin-top: 24/$r;
  background: #F7F7F7;
  font-size: 24/$r;
  font-family: Source Han Sans CN;
  font-weight: 500;
  color: #333333;
  line-height: 36/$r;
}

:deep(.van-cell) {
  padding: 20/$r 46/$r 14/$r 20/$r;
}

.custom-class {
  :deep(.van-tabs__wrap){
    height: 88/$r;
  }
}
</style>
